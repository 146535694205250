module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["it","en"],"defaultLanguage":"it","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://www.zappyrent.com","pages":[{"matchPath":"/pubblica-annuncio-gratis","languages":["it"]},{"matchPath":"/come-affittare-casa","languages":["it"]},{"matchPath":"/protezione-zappyrent","languages":["it"]},{"matchPath":"/phone-ok","languages":["it"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
